import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9658a1b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-input" }
const _hoisted_2 = ["multiple", "accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      ref: "input",
      type: "file",
      name: "file-input",
      class: "file-input_input",
      multiple: _ctx.multiple,
      accept: _ctx.fileTypes,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputChange($event)))
    }, null, 40, _hoisted_2),
    _createElementVNode("button", {
      ref: "button",
      class: "file-input_button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickInput())),
      onDragenter: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.dragEnter()), ["prevent"])),
      onDragleave: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.dragLeave()), ["prevent"])),
      onDragover: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.dropInput($event)), ["prevent"]))
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.fileInputLabel), 1),
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.fileInputIconClass)
      }, null, 2)
    ], 544)
  ]))
}